import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["promptId", "prefixPrompt", "bookId", "apiModelName", "maxTokens"];

  connect() {
    this.loadContent();
  }

  updateMaxTokens() {
    const selectedModel = this.apiModelNameTarget.value;
    if (selectedModel === "ChatGPT") {
      this.maxTokensTarget.value = 3000;
    } else if (selectedModel === "ChatGPT-16k") {
      this.maxTokensTarget.value = 7000;
    } else if (selectedModel === "GPT-4") {
      this.maxTokensTarget.value = 7000;
    } else if (selectedModel === "GPT-4-32K") {
      this.maxTokensTarget.value = 7000;
    } 
  }

  updatePrefixPromptOnBookChange() {
    this.prefixPromptTarget.value = "Use the following pieces of context to answer the question at the end. If you don't know the answer, just say that you don't know, don't try to make up an answer.";
    this.prefixPromptTarget.dispatchEvent(new Event('input'));
  }

  loadContent() {
    const promptId = this.promptIdTarget.value;

    if (promptId) {
      fetch(`/prompts/${promptId}`, { headers: { "Accept": "application/json" } })
        .then(response => response.json())
        .then(data => {
          this.prefixPromptTarget.value = data.content;
          // 触发 input 事件 （讓他可以 autogrow）
          this.prefixPromptTarget.dispatchEvent(new Event('input'));
        });
    } else {
      //this.prefixPromptTarget.value = "";
    }
  }
}
