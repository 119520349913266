import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    vault: String,
    filename: String,
    contentId: String
  }

  copyToObsidian(event) {
    event.preventDefault()
    const contentElement = document.getElementById(this.contentIdValue)
    if (!contentElement) {
      console.error('Content element not found')
      return
    }
    const content = contentElement.innerText
    if (!content) {
      alert('No content to copy')
      return
    }
    const vault = this.vaultValue || 'MyNote'
    const fileName = this.formatFileName()
    const encodedFileName = encodeURIComponent(fileName)
    const encodedContent = encodeURIComponent(content)
    const obsidianUri = `obsidian://new?vault=${encodeURIComponent(vault)}&file=${encodedFileName}&content=${encodedContent}`
    window.location.href = obsidianUri
  }

  formatFileName() {
    const now = new Date()
    const timestamp = now.toISOString().slice(0, 19).replace('T', '-').replace(/:/g, '-')
    let fileName = this.filenameValue || `Note_${timestamp}`
    // Apply file name sanitization
    fileName = this.sanitizeFileName(fileName)
    // Ensure the file name ends with .md
    if (!fileName.toLowerCase().endsWith('.md')) {
      fileName += '.md'
    }
    return fileName
  }

  sanitizeFileName(fileName) {
    // Remove or replace unsafe characters while preserving Chinese characters
    const sanitized = fileName.replace(/[^\p{L}\p{N}\p{P}\p{Z}]/gu, '-').replace(/-+/g, '-')
    // Ensure the file name doesn't start or end with '-'
    return sanitized.replace(/^-+|-+$/g, '')
  }
}
