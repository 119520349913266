import {
  Controller
} from "@hotwired/stimulus";

export default class extends Controller {
  reset() {
    this.element.reset()
    // 移除按钮图标的 text-primary 类
    const buttonIcon = document.querySelector('.button-icon');
    if (buttonIcon) {
      buttonIcon.classList.remove('text-primary');
    }
  }
}
