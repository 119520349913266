import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
  static targets = ["input", "output"]

  connect() {
    this.updateGraph()
  }

  updateGraph() {
    let graphvizContent = this.inputTarget.value.trim()

    if (graphvizContent) {
      // 移除可能的 Markdown 代碼塊標記
      graphvizContent = graphvizContent.replace(/```(graphviz|dot)?\n?|```/g, '').trim()

      // 添加或更新圖形屬性
      if (!graphvizContent.includes("graph [")) {
        graphvizContent = "digraph G {\n  graph [rankdir=LR, size=\"5,5!\", ratio=fill];\n" +
                          graphvizContent.slice(graphvizContent.indexOf("{") + 1);
      } else {
        graphvizContent = graphvizContent.replace(/graph \[/, 'graph [rankdir=LR, size="5,5!", ratio=fill, ');
      }

      // 使用 Viz.js 渲染圖形
      const viz = new Viz()
      viz.renderSVGElement(graphvizContent)
        .then(element => {
          // 調整 SVG 大小
          element.setAttribute('width', '100%')
          element.setAttribute('height', 'auto')
          this.outputTarget.innerHTML = ''
          this.outputTarget.appendChild(element)
        })
        .catch(error => {
          console.error("Graphviz 渲染錯誤:", error)
          this.outputTarget.innerHTML = "圖形渲染失敗: " + error
        })
    } else {
      this.outputTarget.innerHTML = "圖形內容無效"
    }
  }
}
