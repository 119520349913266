import { Controller } from "@hotwired/stimulus";
import "./talkify_config";

export default class extends Controller {
  connect() {

    talkify.config.remoteService.host = 'https://talkify.net';
    talkify.config.remoteService.apiKey = '8d7a4687-dff4-49fe-b06a-a51f13d7f110';

    talkify.config.ui.audioControls.enabled = true;
    talkify.config.ui.audioControls.voicepicker.enabled = true;
    talkify.config.ui.audioControls.container = document.getElementById("player-and-voices");


    console.log("connected");

    // Get the language from the data attribute
     const language = this.element.dataset.language;

     // Map the language to a voice name
     const voiceMap = {
       English: "Zira",
       Japanese: "Haruka",
       Spanish: "Helena",
       French: "Hortense",
       Chinese: "HuiHui",
       // Add more languages and voices here...
     };

     const voiceName = voiceMap[language] || "Zira"; // Default to "Haruka"

     // Initialization
     const player = new talkify.TtsPlayer()
       .enableTextHighlighting()
       .forceVoice({ name: voiceName });

    const paragraphs = document.querySelectorAll('.root p'); // Select all paragraphs inside #root

    // Create a playlist for each paragraph
    let playlists = Array.from(paragraphs).map(paragraph => {
      return new talkify.playlist()
        .begin()
        .usingPlayer(player)
        .withRootSelector('.root')
        .withTextInteraction()
        .withElements([paragraph]) // Specify a single paragraph for this playlist
        .build();
    });

    // Event listeners
    paragraphs.forEach((paragraph, index) => {
      paragraph.addEventListener('click', () => {
        // Pause all playlists
        playlists.forEach(playlist => playlist.pause());

      });
    });
  }
}
