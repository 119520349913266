// app/javascript/controllers/graphviz_controller.js
import { Controller } from "@hotwired/stimulus"
import Viz from "viz.js"
import { Module, render } from "viz.js/full.render.js"

export default class extends Controller {
  static targets = ["graph", "content", "saveButton"]
  static values = {
    id: String
  }

  connect() {
    this.renderGraph()
    this.saveButtonTarget.addEventListener('click', this.savePNG.bind(this))
  }

  renderGraph() {
    let graphvizContent = this.contentTarget.textContent.trim()
    if (graphvizContent) {
      if (!graphvizContent.includes("graph [")) {
        graphvizContent = "digraph G {\n  graph [rankdir=LR, size=\"5,5!\", ratio=fill];\n" + graphvizContent.slice(graphvizContent.indexOf("{") + 1)
      } else {
        graphvizContent = graphvizContent.replace(/graph \[/, 'graph [rankdir=LR, size="5,5!", ratio=fill, ')
      }

      const viz = new Viz({ Module, render })
      viz.renderSVGElement(graphvizContent)
        .then(element => {
          this.graphTarget.innerHTML = ''
          this.graphTarget.appendChild(element)
          element.setAttribute('width', '100%')
          element.setAttribute('height', 'auto')
        })
        .catch(error => {
          console.error("Graphviz rendering error:", error)
          this.graphTarget.innerHTML = "Graph rendering failed: " + error
        })
    } else {
      this.graphTarget.innerHTML = "還沒有產生圖片"
    }
  }

  savePNG() {
    const svg = this.graphTarget.querySelector('svg')
    const svgData = new XMLSerializer().serializeToString(svg)

    const scale = 8
    const canvas = document.createElement('canvas')
    const bbox = svg.getBBox()
    canvas.width = bbox.width * scale
    canvas.height = bbox.height * scale

    const ctx = canvas.getContext('2d')
    ctx.clearRect(0, 0, canvas.width, canvas.height)

    const scaledSvg = svgData.replace(/width="[^"]+"/, `width="${canvas.width}"`)
                             .replace(/height="[^"]+"/, `height="${canvas.height}"`)

    canvg(canvas, scaledSvg, {
      ignoreMouse: true,
      ignoreAnimation: true,
      ignoreDimensions: true,
      scaleWidth: canvas.width,
      scaleHeight: canvas.height
    })

    const pngFile = canvas.toDataURL("image/png")
    const downloadLink = document.createElement('a')
    downloadLink.download = `crisp_high_res_graph-${this.idValue}.png`
    downloadLink.href = pngFile
    downloadLink.click()
  }
}
