import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "content" ]
  static values = {
    maxLength: { type: Number, default: 300 }
  }

  connect() {
    console.log("Truncate controller connected")
    this.truncate()
  }

  truncate() {
    console.log("Truncate method called")
    this.contentTargets.forEach(contentTarget => {
      const fullHtml = contentTarget.innerHTML
      const fullText = contentTarget.textContent
      console.log("Full text length:", fullText.length)

      if (fullText.length <= this.maxLengthValue) return

      const truncatedHtml = this.truncateHTML(fullHtml, this.maxLengthValue)
      contentTarget.innerHTML = `
        <div class="truncated-content">${truncatedHtml}</div>
        <div class="full-content" style="display: none;">${fullHtml}</div>
        <button class="btn btn-sm btn-outline-secondary opacity-50 mt-2 show-more">Show More</button>
      `
      contentTarget.querySelector('.show-more').addEventListener('click', this.toggleText.bind(this))
    })
  }
  truncateHTML(html, maxLength) {
    const div = document.createElement('div')
    div.innerHTML = html
    const textContent = div.textContent
    if (textContent.length <= maxLength) return html

    let truncated = ''
    let currentLength = 0
    const walker = document.createTreeWalker(div, NodeFilter.SHOW_TEXT | NodeFilter.SHOW_ELEMENT)

    while (walker.nextNode()) {
      const node = walker.currentNode
      if (node.nodeType === Node.ELEMENT_NODE) {
        truncated += node.outerHTML
      } else if (node.nodeType === Node.TEXT_NODE) {
        const remainingLength = maxLength - currentLength
        if (remainingLength <= 0) break
        const text = node.textContent
        truncated += text.slice(0, remainingLength)
        currentLength += text.length
        if (currentLength >= maxLength) break
      }
    }

    return truncated + '...'
  }

  toggleText(event) {
    const button = event.currentTarget
    const contentTarget = button.closest('[data-truncate-target="content"]')
    const truncatedContent = contentTarget.querySelector('.truncated-content')
    const fullContent = contentTarget.querySelector('.full-content')

    if (button.textContent === 'Show More') {
      truncatedContent.style.display = 'none'
      fullContent.style.display = 'block'
      button.textContent = 'Show Less'
    } else {
      truncatedContent.style.display = 'block'
      fullContent.style.display = 'none'
      button.textContent = 'Show More'
    }
  }

  initialize() {
    super.initialize()
    window.addEventListener("stimulus-reload", this.truncate.bind(this))
  }

  disconnect() {
    window.removeEventListener("stimulus-reload", this.truncate.bind(this))
  }
}
